import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
    return (
        <>

            <section className="breadcrumbs-area parallex">
                <div className="container">
                    <div className="row">
                        <div className="page-title">
                            <div className="col-sm-12 col-md-6 page-heading text-left">
                                <h3>any question</h3>
                                <h2>Contact Us</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 text-right">
                                <ul className="breadcrumbs">
                                    <li><Link to="/">home</Link></li>
                                    <li><Link to="/">pages</Link></li>
                                    <li><Link to="/">team</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact-us" className="section-padding-70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12  ">
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="location-item text-center">
                                    <div className="icon"> <i className="icon-map" /> </div>
                                    <h4 className="text-uppercase">Location</h4>
                                    <p>   Ewshopping, Rajendra Palace, New Delhi, Pin -110008 </p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="location-item text-center">
                                    <div className="icon"> <i className="icon-phone" /> </div>
                                    <h4 className="text-uppercase">Call us 24/7</h4>
                                    <p>
                                        +91 8447282606 </p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="location-item text-center">
                                    <div className="icon"> <i className="icon-envelope" /> </div>
                                    <h4 className="text-uppercase">Mail us</h4>
                                    <p> info@ewcourier.com </p>
                                </div>
                            </div>

                            <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 1200, height: 300 }}><div id="canvas-for-googlemap" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=+Rajendra+Palace,+New+Delhi,+Pin+-110008&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="our-googlemap-code" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="auth-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#canvas-for-googlemap img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>



                            {/* <form id="contactForm" method="post" action="#">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Name<span className="required">*</span></label>
                                        <input type="text" placeholder="Name" id="name" name="name" className="form-control" required />
                                    </div>
                                </div> 
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="email">Email<span className="required">*</span></label>
                                        <input type="email" placeholder="Email" id="email" name="email" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                   
                                    <div className="form-group">
                                        <label>Subject<span className="required">*</span></label>
                                        <input type="text" placeholder="Subject" id="subject" name="subject" className="form-control" required />
                                    </div>
                                </div> 
                                <div className="col-sm-12">
                                    
                                    <div className="form-group">
                                        <label>Message<span className="required">*</span></label>
                                        <textarea placeholder="Message..." id="message" name="message" className="form-control" rows={6} required defaultValue={""} />
                                    </div>
                                </div> 
                                <div className="col-sm-12 text-center">
                                    <button type="submit" id="yes" className="btn btn-primary">Send Message</button>
                                    <img id="loader" alt src="images/loader.gif" className="loader" />
                                </div> 
                            </form> */}

                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Contact