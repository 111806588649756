import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function HomeSwiper() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >

                
                        <SwiperSlide>
                            <div className="item">
                                <div className="col-sm-12 col-md-12 col-xs-12 testimonial-grid text-center"> <img src="assets/images/Profile.jpg" className="img-responsive" alt="Testimonials" />
                                    <p>Excellent service – fast and reasonable quotation, delivery boy turned up promptly and delivered the goods quickly as promised. Will use again and would definitely recommend to others.</p>
                                    <div className="name">Amit Pal</div>
                                    <div className="profession">Engineer</div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="col-sm-12 col-md-12 col-xs-12 testimonial-grid text-center"> <img src="assets/images/Profile.jpg" alt="Testimonials" className="img-responsive" />
                                    <p>The courier came to collect our documents very quickly in less than 30 minutes and took them to our destination in very quick time that he arrived 15 minutes earlier than anticipated. We were notified immediately that the documents had arrived.</p>
                                    <div className="name">Sanjay Kumar</div>
                                    <div className="profession"> HR </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="item">
                                <div className="col-sm-12 col-md-12 col-xs-12 testimonial-grid text-center"> <img src="assets/images/Profile.jpg" alt="Testimonials" className="img-responsive" />
                                    <p>Excellent and professional service. Have used twice and highly recommend – polite delivery boys on each occasion and punctual.</p>
                                    <div className="name">  Abhishek Sharma  </div>
                                    <div className="profession">  Military Officer </div>
                                </div>
                            </div>
                        </SwiperSlide>
                   
            </Swiper>
        </>
    );
}
