import React from 'react'
import { Link } from "react-router-dom";


const HomeServices = () => {
    return (
        <>

            <section className="custom-padding" id="about-section-3">
                <div className="container">
                    <div className="main-heading text-center">
                        <h2>our services</h2>
                    </div>
                    <div className="main-boxes">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-xs-12">
                                <div className="services-grid-1">
                                    <div className="service-image">
                                        <a to="#"><img alt src="assets/images/1.jpg" /></a>
                                    </div>
                                    <div className="services-text">
                                        <h4>Express Standard</h4>
                                        <p>We ensure express deliveries at an optimal cost through air, rail and road.
                                        </p>
                                    </div><br /><br />
                                    <div className="more-about"> <Link className="btn btn-primary btn-lg" to="/Services">Explore <i className="fa fa-chevron-circle-right" /></Link> </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-xs-12">
                                <div className="services-grid-1">
                                    <div className="service-image">
                                        <a to="#"><img alt src="assets/images/3.jpg" /></a>
                                    </div>
                                    <div className="services-text">
                                        <h4>Air Express Cargo</h4>
                                        <p>We offer an express delivery solution for shipments weighing more than 100 kgs across all metros and cities in India.
                                        </p>
                                    </div><br />
                                    <div className="more-about"> <Link className="btn btn-primary btn-lg" to="/Services">Explore <i className="fa fa-chevron-circle-right" /></Link> </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-xs-12">
                                <div className="services-grid-1">
                                    <div className="service-image">
                                        <a to="#"><img alt src="assets/images/2.jpg" /></a>
                                    </div>
                                    <div className="services-text">
                                        <h4>Surface Express Cargo</h4>
                                        <p>We offer an economical delivery solution for heavy shipments which requires special arrangements such as big vehicles and material handling equipment.
                                        </p>
                                    </div>
                                    <div className="more-about"> <Link className="btn btn-primary btn-lg" to="/Services">Explore <i className="fa fa-chevron-circle-right" /></Link> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="section-padding-70" id="about">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-7 col-sm-12 col-xs-12 ">
                            <div className="about-title">
                                <h2>Domestic shipping</h2>
                                <p>Manage all channels in a single view and
                                    reach nationwide efficiently with AI-based
                                    courier selection.</p>
                                <div className="more-about"> <a className="btn btn-primary btn-lg" to="#">Explore <i className="fa fa-chevron-circle-right" /></a> </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 col-xs-12 our-gallery">
                            <img className="img-responsive " alt="Image" src="assets/images/courier-selection.webp" />
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}

export default HomeServices