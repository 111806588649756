import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>

      <footer className="footer-area">
        <div className="footer-content">
          <div className="container">
            <div className="row clearfix">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="col-lg-7 col-sm-6 col-xs-12 column">
                    <div className="footer-widget about-widget">
                      <div className="logo">
                        <a to="index.html"><img src="/assets/images/logo1.png" className="img-responsive" alt /></a>
                      </div>
                      <ul className="contact-info">
                        <li><span className="icon fa fa-map-marker" />  Ewshopping, Rajendra Palace, New Delhi, Pin -110008
                        </li>
                        <li><span className="icon fa fa-phone" /> +91 8447282606</li>
                        <li><span className="icon fa fa-envelope-o" /> info@ewcourier.com</li>
                      </ul>
                      <div className="social-links-two clearfix"> <a to="#" className="facebook img-circle"><span className="fa fa-facebook-f" /></a> <a to="#" className="twitter img-circle"><span className="fa fa-twitter" /></a>
                        <a to="#" className="google-plus img-circle"><span className="fa fa-google-plus" /></a> <a to="#" className="linkedin img-circle"><span className="fa fa-pinterest-p" /></a> <a to="#" className="linkedin img-circle"><span className="fa fa-linkedin" /></a> </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-6 col-xs-12 column">
                    <h2>Our Service</h2>
                    <div className="footer-widget links-widget">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/About">About Us</Link></li>
                        <li><Link to="/OurAssociates">Our Associates</Link></li>
                        <li><Link to="/Services">Services</Link></li>
                        <li><Link to="/Contact">24/7 Support</Link></li>
                        <li><Link to="/Contact">Contact</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="row clearfix">
                  <div className="col-lg-7 col-sm-6 col-xs-12 column">
                    <div className="footer-widget news-widget">
                      <h2>Latest News</h2>
                      <div className="news-post">
                        <div className="icon" />
                        <div className="news-content">
                          <figure className="image-thumb"><img src="/assets/images/blog/popular-2.jpg" alt />
                          </figure>
                          <a to="#">top benefits of hiring our professional EwCourier</a>
                        </div>
                        <div className="time">Dec 11, 2023</div>
                      </div>
                      <div className="news-post">
                        <div className="icon" />
                        <div className="news-content">
                          <figure className="image-thumb"><img src="/assets/images/blog/popular-1.jpg" alt />
                          </figure>
                          <a to="#">top benefits of hiring our professional EwCourier</a>
                        </div>
                        <div className="time">Dec 11, 2023</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-6 col-xs-12 column">
                    <div className="footer-widget links-widget">
                      <h2>Site Links</h2>
                      <ul>
                        <li><Link to="/About">About Us</Link></li>
                        <li><Link to="/Services">Services</Link></li>
                        <li><Link to="/OurAssociates">Our Associates</Link></li>
                        <li><Link to="">Blog</Link></li>
                        <li><Link to="">Contact</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="auto-container clearfix">
            <div className="copyright text-center">© 2023 EwCourier | Design by Dexterous Technology 
            </div>
          </div>
        </div>
      </footer>



    </>
  )
}

export default Footer