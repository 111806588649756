import React from "react";
import { Link } from "react-router-dom";
import HomeBannerSwiper from "../../component/HomeBannerSwiper";

const HomeBanner = () => {
  return (
    <>
      {/* <HomeBannerSwiper/> */}

      <div className="rev_slider_wrapper">
        <div className="rev_slider" data-version={5.0}>
          <ul>
            <li
              data-index="rs-4"
              data-transition="slidingoverlayhorizontal"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="images/slider/thumb-1.jpg"
              data-rotate={0}
              data-saveperformance="off"
              data-title="Web Show"
              data-description
            >
              <img
                src="assets/images/slider/1.jpg"
                alt
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-bgparallax={6}
                data-no-retina
              />
              <div className="first">
                <div
                  className="tp-caption tp-resizeme text-white text-uppercase"
                  id="rs-3-layer-1"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-125']"
                  data-fontsize="['26']"
                  data-lineheight="['64']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 700 }}
                >
                  Ground or Air
                </div>
                <div
                  className="tp-caption tp-resizeme text-uppercase text-white font-merry"
                  id="rs-3-layer-2"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-60']"
                  data-fontsize="['40']"
                  data-lineheight="['84']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 600 }}
                >
                  <span className="text-white">
                    Providing EwCourier Services{" "}
                  </span>
                </div>
                <div
                  className="tp-caption tp-resizeme text-white"
                  id="rs-3-layer-3"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['20']"
                  data-fontsize="['18']"
                  data-lineheight="['34']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1400}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 300 }}
                >
                  We provides always our best services for our clients and
                  always
                  <br /> try to achieve our client's trust and satisfaction.
                </div>
                <div
                  className="tp-caption tp-resizeme"
                  id="rs-3-layer-4"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1600}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", letterSpacing: 1 }}
                >
                  <Link className="btn btn-colored btn-lg" to="/Contact">
                    Contact Now
                  </Link>
                </div>
              </div>
            </li>
            <li
              data-index="rs-1"
              data-transition="slidingoverlayhorizontal"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/slider/thumb-2.jpg"
              data-rotate={0}
              data-saveperformance="off"
              data-title="Web Show"
              data-description
            >
              <img
                src="assets/images/slider/2.jpg"
                alt
                data-bgposition="center top"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-bgparallax={6}
                data-no-retina
              />
              <div className="first">
                <div
                  className="tp-caption tp-resizeme text-uppercase text-white "
                  id="rs-1-layer-1"
                  data-x="['right']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-125']"
                  data-fontsize="['26']"
                  data-lineheight="['64']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 700 }}
                >
                  We Provide Solutions
                </div>
                <div
                  className="tp-caption tp-resizeme text-white font-merry  text-uppercase"
                  id="rs-1-layer-2"
                  data-x="['right']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-60']"
                  data-fontsize="['40']"
                  data-lineheight="['84']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 700 }}
                >
                  {" "}
                  EwCourier Services
                </div>
                <div
                  className="tp-caption tp-resizeme text-right text-white"
                  id="rs-1-layer-3"
                  data-x="['right']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['30']"
                  data-fontsize="['18']"
                  data-lineheight="['34']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1400}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 400 }}
                >
                  We provides always our best services for our clients and
                  always
                  <br /> try to achieve our client's trust and satisfaction.
                </div>
                <div
                  className="tp-caption tp-resizeme"
                  id="rs-1-layer-4"
                  data-x="['right']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1600}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", letterSpacing: 1 }}
                >
                  <Link className="btn btn-colored btn-lg" to="/Contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </li>
            <li
              data-index="rs-2"
              data-transition="slidingoverlayhorizontal"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/slider/thumb-3.jpg"
              data-rotate={0}
              data-saveperformance="off"
              data-title="Web Show"
              data-description
            >
              <img
                src="assets/images/slider/3.jpg"
                alt
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-bgparallax={6}
                data-no-retina
              />
              <div className="first">
                <div
                  className="tp-caption tp-resizeme text-center text-white"
                  id="rs-2-layer-2"
                  data-x="['center']"
                  data-hoffset="['0']"
                  data-y="['middle']"
                  data-voffset="['-60']"
                  data-fontsize="['26']"
                  data-lineheight="['64']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 400 }}
                >
                  - Providing Services Since 2002 -
                </div>
                <div
                  className="tp-caption tp-resizeme text-uppercase text-white font-merry"
                  id="rs-2-layer-3"
                  data-x="['center']"
                  data-hoffset="['0']"
                  data-y="['middle']"
                  data-voffset="['0']"
                  data-fontsize="['40']"
                  data-lineheight="['80']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 700 }}
                >
                  We Provide Solutions
                </div>
                <div
                  className="tp-caption tp-resizeme"
                  id="rs-2-layer-4"
                  data-x="['center']"
                  data-hoffset="['0']"
                  data-y="['middle']"
                  data-voffset="['80']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1400}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", letterSpacing: 1 }}
                >
                  <a className="btn btn-colored  btn-lg" to="/Contact">
                    Contact Now
                  </a>
                </div>
              </div>
            </li>
            <li
              data-index="rs-3"
              data-transition="slidingoverlayhorizontal"
              data-slotamount="default"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="default"
              data-thumb="assets/images/slider/thumb-4.jpg"
              data-rotate={0}
              data-saveperformance="off"
              data-title="Web Show"
              data-description
            >
              <img
                src="assets/images/slider/4.jpg"
                alt
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                className="rev-slidebg"
                data-bgparallax={6}
                data-no-retina
              />
              <div className="first">
                <div
                  className="tp-caption tp-resizeme text-white text-uppercase"
                  id="rs-3-layer-5"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-125']"
                  data-fontsize="['26']"
                  data-lineheight="['64']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 700 }}
                >
                  Ground Or Air
                </div>
                <div
                  className="tp-caption tp-resizeme text-uppercase font-merry"
                  id="rs-3-layer-8"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['-60']"
                  data-fontsize="['40']"
                  data-lineheight="['84']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1000}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 600 }}
                >
                  <span className="text-white">
                    Providing EwCourier Services
                  </span>
                </div>
                <div
                  className="tp-caption tp-resizeme text-white"
                  id="rs-3-layer-9"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['20']"
                  data-fontsize="['18']"
                  data-lineheight="['34']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1400}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", fontWeight: 300 }}
                >
                  We provides always our best services for our clients and
                  always
                  <br /> try to achieve our client's trust and satisfaction.
                </div>
                <div
                  className="tp-caption tp-resizeme"
                  id="rs-3-layer-10"
                  data-x="['left']"
                  data-hoffset="['30']"
                  data-y="['middle']"
                  data-voffset="['100']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-transform_idle="o:1;s:500"
                  data-transform_in="y:100;scaleX:1;scaleY:1;opacity:0;"
                  data-transform_out="x:left(R);s:1000;e:Power3.easeIn;s:1000;e:Power3.easeIn;"
                  data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
                  data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
                  data-start={1600}
                  data-splitin="none"
                  data-splitout="none"
                  data-responsive_offset="on"
                  style={{ zIndex: 5, whiteSpace: "nowrap", letterSpacing: 1 }}
                >
                  <a className="btn btn-colored btn-lg" to="#">
                    View Services
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HomeBanner;
