import React from "react";
import HomeBanner from "./home/HomeBanner";
import HomeAboout from "./home/HomeAboout";
import Testimonial from "./home/Testimonial";
import MultipleChannels from "./home/MultipleChannels";
import HappyClients from "./home/HappyClients";
import HomeServices from "./home/HomeServices";
import TrackConsigment from "./TrackConsigment";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <TrackConsigment/>
      <HomeAboout />
      <HomeServices />
      <Testimonial />
      <MultipleChannels />
      <HappyClients />
    </>
  );
};

export default Home;
