import React from 'react'
import { Link } from "react-router-dom";


const OurAssociates = () => {
    return (
        <>
            <section className="breadcrumbs-area parallex">
                <div className="container">
                    <div className="row">
                        <div className="page-title">
                            <div className="col-sm-12 col-md-6 page-heading text-left">
                                <h3>Who we are</h3>
                                <h2>Our Associates</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 text-right">
                                <ul className="breadcrumbs">
                                    <li><Link to="/">home</Link></li>
                                    <li><Link to="/">pages</Link></li>
                                    <li><Link to="/">Our Associates</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="quote" id="quote">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-12 col-sm-12 col-xs-12 ">
                            <div className="choose-title">
                                <h3>Our Associates</h3>
                                <h2>Why People Choose Us</h2>
                                <p>We are proud to have more than 4000 strong business associates across India. They have been engaged with the organisation for more than a decade. We provide a cost-effective business model which ensures high returns with nominal initial investment. Our goal is to enjoy mutual growth and work towards increasing revenue in harmony with our associates.</p>
                            </div>
                            <div className="choose-services">
                                <ul className="choose-list">
                                    <li>
                                        <div className="choose-box"> <span className="iconbox"><i className="flaticon-logistics-delivery-truck-and-clock" /></span>
                                            <div className="choose-box-content">
                                                <h4>Single unit business associate franchise</h4>
                                                <p>An authorized representative of the company who is appointed at a specific area under a specific pin code. He/she will be responsible for inbound deliveries. There may be multiple single unit associates where the pin code area is vast or the volume of consignments is high.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="choose-box"> <span className="iconbox"><i className="flaticon-woman-with-headset" /></span>
                                            <div className="choose-box-content">
                                                <h4>Master business associate</h4>
                                                <p> An authorized representative of the company who is appointed at a specific area under a specific pin code in a major city or a metropolitan area. This is a bigger franchise than a ‘Single business associate franchise’. Operations will be similar to a mini hub which covers several pin codes. An office space of about 750 to 800 sq.ft. with necessary infrastructure, systems, manpower, vehicles and accessible over a strategic location in the city is a must.</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="choose-box"> <span className="iconbox"><i className="flaticon-logistics-delivery-truck-and-clock" /></span>
                                            <div className="choose-box-content">
                                                <h4>Super business associate</h4>
                                                <p>An authorised representative of the company and appointed at a district headquarter in the tier II / tier III cities of a state.Responsible for business development, network expansion where network expansion may be achieved by appointing single unit franchises at all potential locations/areas in the district and talukas.</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-5 col-sm-12 no-extra col-xs-12">
                            <div className="quotation-box">
                                <h2>CONTACT US</h2>
                                <div className="desc-text">
                                    <p>Complete our online form below to send an email to our Customer Service team.</p>
                                </div>
                                <form action="contact.html" method="post">
                                    <div className="row clearfix">
                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <input className="form-control" type="text" placeholder="Your Name"  />
                                        </div>
                                        
                                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                            <input className="form-control" type="text" placeholder="Subject"  />
                                        </div>
                                        
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <input className="form-control" type="email" placeholder="Email Address"  />
                                        </div>
                                        
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                            <textarea className="form-control" rows={7} cols={20} placeholder="Your Message" name="your-message" defaultValue={""} />
                                        </div>
                                        
                                        <div className="form-group col-md-12 col-sm-12 col-xs-12 text-right"> <a className="custom-button light">Submit</a> </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OurAssociates