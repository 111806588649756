import React, { useState } from "react";
import { Link } from "react-router-dom";

const Hadear = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <header className="header-area">
        <div className="logo-bar">
          <div className="container clearfix">
            <div className="logo">
              <Link to="">
                <img src="/assets/images/logo1.png" alt />
              </Link>
            </div>
            <div className="information-content">
              <div className="info-box  hidden-sm">
                <div className="icon">
                  <span className="icon-envelope" />
                </div>
                <div className="text">EMAIL</div>
                <Link to="mailt:contact@scriptsbundle.com">
                  info@ewcourier.com
                </Link>
              </div>
              <div className="info-box">
                <div className="icon">
                  <span className="icon-phone" />
                </div>
                <div className="text">Call Now</div>
                <Link className="location" to="#">
                  +91 8447282606
                </Link>
              </div>
              <div className="info-box">
                <div className="icon">
                  <span className="icon-map-pin" />
                </div>
                <div className="text">Find Us</div>
                <Link className="location" to="#">
                  Rajendra Palace, New Delhi, Pin -110008{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="navigation-2">
          <nav className="navbar navbar-default ">
            <div  className=" container-fluid">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => setToggle(!toggle)}
              >
            <div className="img-log">
                <Link to="">
                  <img   src="/assets/images/logo1.png" alt />
                </Link>
                </div>
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#main-navigation"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>
              <div
                className="navbar-header"
                style={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => setToggle(!toggle)}
              >
                {/* <Link to="/">
                    <img src="/assets/images/logo1.png" alt />
                  </Link>
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#main-navigation"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button> */}
              </div>
              <div className="mobile_version">
                {toggle === false ? (
                  <>
                    <div
                      className="collapse navbar-collapse"
                      id="main-navigation"
                      style={{padding:'5px'}}
                    >
                      <ul className="nav navbar-nav">
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/About">About Us</Link>
                        </li>
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/OurAssociates">Our Associates</Link>
                        </li>
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/Services">Services</Link>
                        </li>
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/Contact">Contact Us</Link>
                        </li>
                        <li
                          className="hidden-sm"
                          onClick={() => setToggle(!toggle)}
                        >
                          <Link to="/consignment">Track Consignment</Link>
                        </li>
                      </ul>
                      <Link
                        to="/Contact"
                        className="btn btn-primary pull-right"
                      >
                        Try for Free
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="desktop_version">
                <div className="collapse navbar-collapse" id="main-navigation">
                  <ul className="nav navbar-nav">
                    <li className="hidden-sm">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="hidden-sm">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="hidden-sm">
                      <Link to="/OurAssociates">Our Associates</Link>
                    </li>
                    <li className="hidden-sm">
                      <Link to="/Services">Services</Link>
                    </li>
                    <li className="hidden-sm">
                      <Link to="/Contact">Contact Us</Link>
                    </li>
                    <li className="hidden-sm">
                      <Link to="/consignment">Track Consignment</Link>
                    </li>
                  </ul>
                  <Link to="/Contact" className="btn btn-primary pull-right">
                    Try for Free
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Hadear;
