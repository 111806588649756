import React from 'react'
import { Link } from "react-router-dom";
import HomeServices from '../home/HomeServices';
import HappyClients from '../home/HappyClients';


const Services = () => {
    return (
        <>
            <section className="breadcrumbs-area parallex">
                <div className="container">
                    <div className="row">
                        <div className="page-title">
                            <div className="col-sm-12 col-md-6 page-heading text-left">
                                <h3>Who we are</h3>
                                <h2>Services</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 text-right">
                                <ul className="breadcrumbs">
                                    <li><Link to="/">home</Link></li>
                                    <li><Link to="/">pages</Link></li>
                                    <li><Link to="/">Services</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeServices />
            <section className="custom-padding services-2">
                <div className="container">
                <div className="main-heading text-center">
                        <h2>Value Added Services</h2>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-xs-12 col-md-12 ">
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-box-of-packing-for-delivery" />
                                    <h4>Risk Cover/ Risk Surcharge</h4>
                                    <p>Our customers can opt for a risk cover for their consignment. It is like an insurance and covers the holder for the value of the shipment in case of any loss in transit or faulty packaging. We charge a non-refundable risk surcharge equivalent to 2% of the invoice value.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-ocean-transportation" />
                                    <h4>Cash on delivery</h4>
                                    <p>Customers can collect the value of their shipment from the consignee/receiver at the time of delivery. This service can be clubbed with ToPay. We collect COD/DOD from the consignee/receiver and hand the same to the consignor/sender. This provision is only available for our corporate / credit client.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-delivery-truck" />
                                    <h4>ToPay </h4>
                                    <p>We provide ToPay facility to our contractual customers where we collect freight charges from the consignee/receiver instead of the consignor/sender.</p>
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="main-heading text-center">
                        <h2>Additional Services</h2>
                    </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-sea-ship-with-containers" />
                                    <h4>Reverse Pickup Solutions</h4>
                                    <p>We provide hassle-free pickup facilities for corporates from their respective sellers/suppliers across India.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-logistics-delivery-truck-in-movement" />
                                    <h4>International Delivery Solutions</h4>
                                    <p> We provide delivery solutions across borders for documents and parcels.</p>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4 col-xs-12">
                                <div className="services-box-2 text-center">
                                    <i className="flaticon-24-hours-symbol" />
                                    <h4>Integrated Mailroom Management Solutions </h4>
                                    <p>A comprehensive & customized integrated business program for mailroom of a big corporate. Organisations may favour an on-site digital mailroom as it offers oversight and access.</p>
                                </div>
                            </div>
                            <div className="clearfix" />
                        </div>
                    </div>
                </div>
            </section>
            <HappyClients />


        </>
    )
}

export default Services