import React from 'react'

const HappyClients = () => {
    return (
        <>
            <div className="parallex section-padding-70 fun-facts-bg text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="statistic-percent" data-perc={356}>
                                <div className="facts-icons"> <span className="flaticon-woman-with-headset" /> </div>
                                <div className="fact"> <span className="percentfactor">356+ </span>
                                    <p>Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="statistic-percent" data-perc={126}>
                                <div className="facts-icons"> <span className="flaticon-commercial-delivery-symbol-of-a-list-on-clipboard-on-a-box-package" />
                                </div>
                                <div className="fact"> <span className="percentfactor">126+ </span>
                                    <p>Order Received</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="statistic-percent" data-perc={274}>
                                <div className="facts-icons"> <span className="flaticon-free-delivery-truck" /> </div>
                                <div className="fact"> <span className="percentfactor">274+ </span>
                                    <p>Free Delivery</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="statistic-percent" data-perc={434}>
                                <div className="facts-icons"> <span className="flaticon-ocean-transportation" /> </div>
                                <div className="fact"> <span className="percentfactor">434+ </span>
                                    <p>Completed Projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HappyClients