import React from 'react'
import MultipleChannelsSwiper from '../../component/MultipleChannelsSwiper'

const MultipleChannels = () => {
    return (
        <>

            <section className="section-padding-70 services">
                <div className="container">
                    <div className="main-heading text-center">
                        <h2>Manage multiple channels in one place</h2>
                        <p>Integrate 12+ sales channels to easily manage your orders, inventory and more.</p>
                    </div>
                    <div className="row">
                        <div id="clients" className="text-center">
                        <MultipleChannelsSwiper />
                           
                           
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MultipleChannels