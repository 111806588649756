import React from 'react'
import { Link } from "react-router-dom";
import Testimonial from '../home/Testimonial';
import MultipleChannels from '../home/MultipleChannels';
import HappyClients from '../home/HappyClients';


const About = () => {
    return (
        <>

            <section className="breadcrumbs-area parallex">
                <div className="container">
                    <div className="row">
                        <div className="page-title">
                            <div className="col-sm-12 col-md-6 page-heading text-left">
                                <h3>Who we are</h3>
                                <h2>About Us</h2>
                            </div>
                            <div className="col-sm-12 col-md-6 text-right">
                                <ul className="breadcrumbs">
                                    <li><Link to="/">home</Link></li>
                                    <li><Link to="/">pages</Link></li>
                                    <li><Link to="/">about us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding-70" id="about">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-7 col-sm-12 col-xs-12 ">
                            <div className="about-title">
                                <h3>who we are</h3>
                                <h2>About Our Company</h2>
                                <p>EwCourier is proud to have a can-do attitude backed up by 13 years of experience. Embarking on a journey in 2010 with only two offices in Delhi & Mumbai, we have spread our unstoppable presence across the country. We are humbled by our phenomenal success 
                                    Currently, we handle over 2 lakh consignments whilst serving over 5000 pin codes every day.</p>
                                <p>In 2013, we successively launched our premium product - Prime Track which guarantees a rapid service and a prompt delivery. It has been premediated to cater valuable and urgent consignments.</p>
                                <p>As a growing family of 10,000 diverse employees, successful business associates and dedicated franchisees, we promise to fulfil customer requirements.</p>
                                <p>In future, we hope to grow further in terms of revenue, profit and the quality of our services.</p>
                                {/* <div className="more-about"> <a className="btn btn-primary btn-lg" to="#">Read More <i className="fa fa-chevron-circle-right" /></a> </div> */}
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 col-xs-12 our-gallery">
                            <img className="img-responsive " alt="Image" src="assets/images/delivery_man.png" />
                        </div>
                    </div>
                </div>
            </section>
            <Testimonial />
            <MultipleChannels />
            <HappyClients />

        </>
    )
}

export default About