import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function MultipleChannelsSwiper() {
    return (
        <>
            <Swiper
                slidesPerView={2}
                spaceBetween={5}
                centeredSlides={true}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/amazon.png" />
                        </div>
                    </div>

                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/aramex.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/ebay.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/shopify.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/amazon.png" />
                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/aramex.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/ebay.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/shopify.png" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item">
                        <div className="clients-grid"> <img className="img-responsive" alt src="assets/images/clients/amazon.png" />
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
