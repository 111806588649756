import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useNavigate } from "react-router-dom";

const TrackConsigment = () => {
  const [btnPress, setbtnPress] = useState(false);

  const navigate = useNavigate();

  const [trackId, settrackId] = useState("");
  const [trackIdError, settrackIdError] = useState("");

  const trackidPress = () => {
    setbtnPress(true);

    if (trackId === "") {
      settrackIdError("Required");
    } else {
      settrackIdError("");
    }
    if (trackId !== "") {
      const value = trackId.toUpperCase();
      navigate(`/courier-track/${value}`);
    }
  };

  return (
    <>
      <div className="trackdiv_home">
        <div className="trackdiv_main">
          <span className="spaan_title">Track Consignment</span>
          <p>Enter the EWC Number to track consignment</p>

          <div className="input_divs">
            <Input
              status={trackIdError !== "" ? "error" : ""}
              value={trackId}
              onChange={(e) => settrackId(e.target.value)}
              placeholder="Enter EWC Number"
              size="large"
            />
          </div>
          <div className="input_divs">
            <Button
              onClick={() => trackidPress()}
              className="button_track"
              type="primary"
            >
              Track{" "}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackConsigment;
