import React from 'react'
import { Link } from "react-router-dom";


const HomeAboout = () => {
    return (
        <>
            <section className="section-padding-70" id="about">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-md-7 col-sm-12 col-xs-12 ">
                            <div className="about-title">
                                <h3>who we are</h3>
                                <h2>About Our Company</h2>
                                <p>EwCourier is proud to have a can-do attitude backed up by 13 years of experience. Embarking on a journey in 2010 with only two offices in Delhi & Mumbai, we have spread our unstoppable presence across the country. We are humbled by our phenomenal success.  Currently, we handle over 2 lakh consignments whilst serving over 5000 pin codes every day.</p>
                                <div className="more-about"> <Link className="btn btn-primary btn-lg" to="/About">Read More <i className="fa fa-chevron-circle-right" /></Link> </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12 col-xs-12 our-gallery">
                            <img className="img-responsive " alt="Image" src="assets/images/about-us.webp" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeAboout