import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdAirplane } from "react-icons/io";
import { FaFileAlt } from "react-icons/fa";
import { IoPinSharp } from "react-icons/io5";
import moment from "moment/moment";
import { Timeline, Button, Result } from "antd";
import axios from "axios";

const CourierTrack = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [courier_Details, setcourier_Details] = useState("");
  const [recivedAddress, setrecivedAddress] = useState("");
  const [sendAddress, setsendAddress] = useState("");

  const [loading, setloading] = useState(true);

  const [curier_Timeline, setcurier_Timeline] = useState([]);

  useEffect(() => {
    if (params.tracking_id) {
      const fetch_Courier = async () => {
        const url = `https://ewscourier.bayfikar.in/api/v1/courier/get-single-courier/${params.tracking_id}`;
        // const url = `http://localhost:4070/api/v1/courier/get-single-courier/${params.tracking_id}`;
        const get_single = await axios.get(url);
        if (get_single.data.success) {
          const courier = get_single.data.courier;
          setcourier_Details(courier);
          setrecivedAddress(courier.recipient_address);
          setsendAddress(courier.sender_address);

          const curier_timeline_array = [...courier.assign_to].reverse();
          let display_array = [];
          for (let index = 0; index < curier_timeline_array.length; index++) {
            let element = curier_timeline_array[index];
            const city_up = element.city.toLowerCase();
            let obj = {
              color: "green",
              children: (
                <>
                  <p
                    style={
                      index === 0
                        ? {
                            fontWeight: "500",
                          }
                        : {}
                    }
                  >
                    {city_up} - INDIA {element.point_discription}
                  </p>
                  <p
                    style={
                      index === 0
                        ? {
                            fontWeight: "500",
                          }
                        : {}
                    }
                  >
                    {moment(element.date).format("DD MMM, YYYY h:mm A")}
                  </p>
                </>
              ),
            };
            display_array.push(obj);
          }
          setcurier_Timeline([...display_array]);
        }
        setloading(false);
      };
      fetch_Courier();
    }
  }, [params.tracking_id]);
  return (
    <>
      {loading === false && (
        <>
          {courier_Details === "" ? (
            <>
              <Result
                status="404"
                title="404"
                subTitle="Unable to fetch tracking data currently"
                extra={
                  <Button
                    type="primary"
                    onClick={() => navigate("/consignment")}
                  >
                    Back
                  </Button>
                }
              />
            </>
          ) : (
            <>
              <div className="main_fragnent_container p-2">
                <div className="profile_container_header">
                  <div className="profile_co_header_left">
                    {/* <span>Courier {courier_Details.trackingId}</span> */}
                    {/* <span>Package Details</span> */}
                    <span
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      Student
                    </span>
                  </div>
                  <div className="profile_co_header_right "></div>
                </div>

                <div className="ship_ment_deatils">
                  <div className="ship_div_main">
                    <div className="pack_details">
                      <div className="pack_box1">
                        <div className="pack_box1_ttitle">
                          <span>Package Details</span>
                        </div>
                        <div className="pack_box1_cat">
                          <span>
                            <IoMdAirplane /> Domestic
                          </span>

                          <span>
                            <FaFileAlt />{" "}
                            {courier_Details.description_of_couriers}
                          </span>
                        </div>

                        <div className="pack_box1_cat margins_tops">
                          <span>{courier_Details.weight} |</span>
                          <span>₹ {courier_Details.price}</span>
                        </div>
                        <div className="pack_box1_cat">
                          <span id="courier">
                            Courier Id: {courier_Details.trackingId}
                          </span>
                        </div>
                        <div className="pack_box1_cat">
                          <span id="courier">
                            Mobile: {courier_Details.sender_phoneNumber}
                          </span>
                        </div>
                      </div>

                      <div className="pack_box2 ">
                        <div className="pack_box1_ttitle">
                          <span>Status</span>
                        </div>

                        <div className="status_box">
                          <div className="status_box1_ttitl">
                            <span>Package Details</span>
                          </div>
                          <div className="status_box2_detai">
                            <div className="pack_box2_ti">
                              <span className="mx-1">
                                {moment(courier_Details.createdAt).format(
                                  "DD MMM, YYYY"
                                )}{" "}
                                |
                              </span>
                              <span className="">
                                {moment(courier_Details.createdAt).format(
                                  "h:mm A"
                                )}
                              </span>
                            </div>
                            <div className="pack_box2_tititle">
                              <span>{courier_Details.last_assign_city} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trav_details">
                      <div className="trav_display_main">
                        <span>
                          <IoPinSharp /> {sendAddress.city}
                        </span>
                        <span className="fast_imag">
                          <img src="/assets/delivery.png" alt="" />
                        </span>
                        <span>
                          <IoPinSharp /> {recivedAddress.city}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="ship_ment_deatils div_centerss">
                  <div className="timeline_container">
                    <Timeline items={curier_Timeline} />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CourierTrack;
