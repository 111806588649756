import React from 'react'
import HomeSwiper from '../../component/HomeSwiper'

const Testimonial = () => {
  return (
    <>
     

      <section class="testimonial-bg parallex section-padding text-center">
      <div className="main-heading text-center">
        <h2>Testimonial</h2>
      </div>
        <div class="container">
          <div id="testimonials">
            <HomeSwiper />
          </div>
        </div>
      </section>


    </>
  )
}

export default Testimonial